import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/images/landing-page/footer-logo-new.svg'
import Facebook from '../assets/images/landing-page/fb.png'
import Behence from '../assets/images/landing-page/be.png'
import CryptoJS from 'crypto-js'
import Linkedin from '../assets/images/landing-page/linkedin.png'
import { useEffect } from 'react'
import { useState } from 'react'
const Footer = () => {
  const checkType = JSON.parse(localStorage.getItem('checkType'))
  const [data1, setData1] = useState('')
  useEffect(() => {
    if (checkType) {
      const handleLogin = async () => {
        const secretPass = 'XkhZG4fW2t2W'
        var bytes = CryptoJS.AES.decrypt(checkType, secretPass)
        setData1(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)))
      }
      handleLogin()
    }
  }, [checkType])
  return (
    <>
      <footer className="" >
      <div className="p-4 text-center dis">
        <b>*Disclaimer:</b> The reference papers from myassignmenthelpgroup.com should only be used as examples for
        students, they should not be turned in exactly as they are. These documents should only be
        used for research and reference purposes.
      </div>
        <div className="main-container container footer-site" >
          <div className="row justify-content-between align-items-start" >
            {/* <div className="col-12 col-md-3  logo-footer">
              <Link className="navbar-brand" to={data1 == 'teacher' || 'student' ? '/' : '/home'}>
                <img src={logo} className="img-fluid " alt="logo" />
              </Link>
         
            </div> */}

            <div className="col-12 col-md-6 col-lg-3 text-center " >
              <ul className="text-center text-lg-start knowledge-ul ps-0" style={{listStyle : 'none'}}>
                <li className="mb-2">
                  <Link
                    to="/copyrightpolicy"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-pages"
                  >
                    Copy Right Policy
                  </Link>
                </li>
                <li className="mb-3" >
                  <Link
                    to="/honorcode"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-pages"
                  >
                    Honor Code
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center" >
              <ul className="text-center text-lg-start knowledge-ul ps-0" style={{listStyle : 'none'}}>
                <li className="mb-2" >
                  <Link
                    to="/generalpolicies"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-pages"
                  >
                    General Policies
                  </Link>
                </li>
                <li className="mb-3">
                  <Link
                    to="/termsofuse"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="link-pages"
                  >
                    Terms Of Use
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3 text-center">
              <ul className="text-center text-lg-start knowledge-ul ps-0" style={{listStyle : 'none'}}>
                <li className="mb-2">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/copyrighttakedownrequest"
                    className="link-pages"
                  >
                    Copyright Takedown Request
                  </Link>
                </li>
                <li className="mb-3">
                  <Link
                    target="_blank"
                    rel="noopener noreferrer"
                    to="/teachercoolprivacypolicy"
                    className="link-pages"
                  >
                    Myassignmenthelpgroup Privacy Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-3  social">
              <a
                href="https://www.facebook.com/profile.php?id=100085517065803"
                rel="noreferrer"
                target="_blank"
                className="link-social"
              >
                <img src={Facebook} className="img-fluid" alt="facebook" />
              </a>
              <a
                href="https://www.instagram.com/myassignmenthelpgroup/"
                className="link-social"
                rel="noreferrer"
                target="_blank"
              >
             <svg
                className="border rounded-circle bg-white mx-1 p-1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="28"
                height="28"
                viewBox="0 0 256 256"
                style={{ fill: 'transparent' }}
              >
                <g
                  fill="#165C89"
                  fillRule="nonzero"
                  stroke="none"
                  strokeWidth="1"
                  strokeLinecap="butt"
                  strokeLinejoin="miter"
                  strokeMiterlimit="10"
                  strokeDasharray=""
                  strokeDashoffset="0"
                  fontFamily="none"
                  fontWeight="none"
                  fontSize="none"
                  textAnchor="none"
                  style={{ mixBlendMode: 'normal' }}
                >
                  <g transform="scale(10.66667,10.66667)">
                    <path d="M18,1H6C3.794,1,2,2.794,2,5v12c0,2.206,1.794,4,4,4h12c2.206,0,4-1.794,4-4V5C22,2.794,20.206,1,18,1z M20,17c0,1.103-0.897,2-2,2H6c-1.103,0-2-0.897-2-2V5c0-1.103,0.897-2,2-2h12c1.103,0,2,0.897,2,2V17z M16,6.5c0,0.828-0.672,1.5-1.5,1.5S13,7.328,13,6.5S13.672,5,14.5,5S16,5.672,16,6.5z M12,8c-2.209,0-4,1.791-4,4s1.791,4,4,4s4-1.791,4-4S14.209,8,12,8z M12,14c-1.103,0-2-0.897-2-2s0.897-2,2-2s2,0.897,2,2S13.103,14,12,14z"></path>
                  </g>
                </g>
              </svg>
              </a>
              <a
                href="https://www.linkedin.com/in/teacher-cool-2552ba250/ "
                className="link-social"
                rel="noreferrer"
                target="_blank"
              >
                <img src={Linkedin} className="img-fluid ms-1" alt="linkedin" />
              </a>
            </div>
          </div>
          
        </div>
      </footer>
      <div className="copyright">Copyright © 2023 myassignmenthelpgroup.com. All rights reserved</div>
    </>
  )
}

export default Footer
